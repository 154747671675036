
import {
  defineAsyncComponent,
  defineComponent,
  PropType,
  SetupContext,
} from 'vue';

/** Interfaces */
import { NegotiationsI } from '@/interfaces/general.interface';

/** Composables */
import { usePaymentCard } from '@/composition/usePaymentCard';
import { ObligationTypes } from '@/utils/multi-pdf-types';

export default defineComponent({
  name: 'NegotiationCard',
  components: {
    Tooltips: defineAsyncComponent(
      () => import('@/components/shared/Tooltips.vue')
    ),
    Loader: defineAsyncComponent(
      () => import('@/components/loader/Loader.vue')
    ),
  },
  props: {
    negotiations: {
      type: Array as PropType<NegotiationsI[]>,
      required: true,
    },
    isLoadingPaymentStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['view-detail-plan', 'make-payment'],
  setup(_props, context: SetupContext) {
    const obligationType = ObligationTypes.NEGOTIATION;
    const {
      bindClassState,
      hiddenPartialData,
      viewDetailPlan,
      makePayment,
      hasBussiness,
      getReference,
      canMakePayment,
      getLastPaymentStatus,
    } = usePaymentCard(context);

    return {
      bindClassState,
      hiddenPartialData,
      viewDetailPlan,
      makePayment,
      hasBussiness,
      obligationType,
      getReference,
      canMakePayment,
      getLastPaymentStatus,
    };
  },
});
